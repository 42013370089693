<template>
  <div class="container-fluid">
    <MobileGuide />
    <h2 class="roboto bold">Reports</h2>
    <hr />
    <div class="mb-5 text-nowrap border-bottom overflow-auto">
      <router-link to="/order-report" class="btn btn-wz-tab">Order Report</router-link>
      <router-link to="/top-sales-report" class="btn btn-wz-tab">Top Sales Report</router-link>
    </div>
    <OrderReport v-if="urlPath.indexOf('/order-report') == 0" />
    <TopSales v-else />
  </div>
</template>
<script>
import MobileGuide from "@/components/navs/MobileGuide";
import OrderReport from "@/components/report/OrderReport";
import TopSales from "@/components/report/TopSales";

export default {
  name: "ReportList",
  components: {
    MobileGuide,
    OrderReport,
    TopSales,
  },
  computed: {
    urlPath() {
      return this.$route.path
    }
  }
}
</script>
