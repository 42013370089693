<template>
  <div>
    <b-form @submit.prevent>
      <div class="row align-items-end">
        <div class="col-md-2 mb-2">
          Period From:
          <input type="date" ref="period" :max="new Date().toISOString().substr(0, 10)" class="form-control" />
        </div>
        <div class="col-md-2 mb-2">
          To:
          <input type="date" ref="period2" :max="new Date().toISOString().substr(0, 10)" class="form-control" />  
        </div>
        <div class="col-md-8 mb-2 d-flex">
          <button class="btn btn-success" @click="filterByPeriod()"><font-awesome icon="search" /> View</button>
          <button class="btn btn-secondary ml-auto" @click="exportCsv()"><font-awesome icon="download"/> Download</button>
        </div>
      </div>
    </b-form>
    <div class="table-responsive">
      <table class="table text-nowrap">
        <thead class="thead-light">
          <th>Item SKU</th>
          <th>Item Name</th>
          <th>Item Brand</th>
          <th>Category</th>
          <th>Qty Sold</th>
          <th>Total Checkout (All Time)</th>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>{{item.sku}}</td>
            <td><router-link :to="'/detail-product/' + item.id">{{item.product_name}}</router-link></td>
            <td>{{item.brand}}</td>
            <td>{{item.category}}</td>
            <td>{{item.qty}}</td>
            <td>{{item.sold}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      class="float-right"
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      @change="getTopSales(period, period2, $event, limit)"
    />
    <select
      class="form-control w-auto float-right" 
      v-model="limit"
      @input="getTopSales(period, period2, 1, $event.target.value)"
    >
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
    </select>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

import moment from "moment";
const XLSX = require("xlsx");

export default {
  name: "OrderReport",
  data() {
    return {
      period: null,
      period2: null,
      items: [],
      page: 1,
      limit: 5,
      total: 0,
    }
  },
  mounted() {
    this.getTopSales(this.period, this.period2, 1, this.limit)
  },
  methods: {
    filterByPeriod(){
      this.period = this.$refs.period.value
      this.period2 = this.$refs.period2.value
      this.getTopSales(this.period, this.period2, 1, this.limit)
    },
    async exportCsv() {
      try {
        const res = await this.$api.report.getTopSales(this.period, this.period2, 0, 1000)
        if (res.status === 200) {
          const arrData = res.data.data.map(el => ({
              sku: el.sku,
              product_name: el.product_name,
              brand: el.brand,
              category: el.category,
              qty: el.qty,
              sold: el.sold,
            }))
          // https://lifesaver.codes/answer/how-to-simply-export-a-worksheet-to-xlsx
          /* make the worksheet */
          const ws = XLSX.utils.json_to_sheet(arrData);
          /* add to workbook */
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          /* generate an XLSX file */
          XLSX.writeFile(wb, "top_sales_report.xlsx");
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    async getTopSales(period, period2, offset, limit) {
      try {
        const res = await this.$api.report.getTopSales(period, period2, offset - 1, limit)
        if (res.status === 200) {
          this.items = res.data.data
          this.total = res.data.meta.total
          this.page = offset
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    formatPrice(value) {
      return value ? value.toLocaleString('id', {minimumFractionDigits: 2}) : value
    },
    formatDate(value, format = "YYYY-MM-DD") {
      return value ? moment(String(value)).format(format).toString() : value
    }
  }
}
</script>
