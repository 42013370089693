<template>
  <div>
    <b-form @submit.prevent>
      <div class="row align-items-end">
        <div class="col-md-2 mb-2">
          Period From:
          <input type="date" ref="period" :max="new Date().toISOString().substr(0, 10)" class="form-control" />
        </div>
        <div class="col-md-2 mb-2">
          To:
          <input type="date" ref="period2" :max="new Date().toISOString().substr(0, 10)" class="form-control" />  
        </div>
        <div class="col-md-8 mb-2 d-flex">
          <button class="btn btn-success" @click="filterByPeriod()"><font-awesome icon="search" /> View</button>
          <button class="btn btn-secondary ml-auto" @click="exportCsv()"><font-awesome icon="download"/> Download</button>
        </div>
      </div>
    </b-form>
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-light">
          <th>Date</th>
          <th>Order No.</th>
          <th>Customer</th>
          <th>Payment Type</th>
          <th>Amount (IDR)</th>
          <th>Status</th>
          <th>Shipping</th>
        </thead>
        <tbody>
          <tr v-for="(order, i) in orders" :key="i">
            <td>{{order.date}}</td>
            <td>{{order.order_number}}</td>
            <td>{{order.customer_name}}</td>
            <td>{{order.payment_type}}</td>
            <td>{{formatPrice(order.total)}}</td>
            <td>{{order.status}}</td>
            <td v-html="order.buyer_address"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      class="float-right"
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      @change="getOrderReport(period, period2, $event, limit)"
    />
    <select
      class="form-control w-auto float-right" 
      v-model="limit"
      @input="getOrderReport(period, period2, 1, $event.target.value)"
    >
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
    </select>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

import moment from "moment";
const XLSX = require("xlsx");

export default {
  name: "OrderReport",
  data() {
    return {
      period: null,
      period2: null,
      orders: [],
      page: 1,
      limit: 5,
      total: 0,
    }
  },
  mounted() {
    this.getOrderReport(this.period, this.period2, 1, this.limit)
  },
  methods: {
    filterByPeriod(){
      this.period = this.$refs.period.value
      this.period2 = this.$refs.period2.value
      this.getOrderReport(this.period, this.period2, 1, this.limit)
    },
    async exportCsv() {
      try {
        const res = await this.$api.report.getOrderReport(this.period, this.period2, 0, 1000)
        if (res.status === 200) {
          const arrData = res.data.data.map(el => ({
              date: el.date,
              order_number: el.order_number,
              customer_name: el.customer_name,
              payment_type: el.payment_type,
              total: el.total,
              status: el.status,
              buyer_address: el.buyer_address.replace(/\n/g, " "),
            }))
          // https://lifesaver.codes/answer/how-to-simply-export-a-worksheet-to-xlsx
          /* make the worksheet */
          const ws = XLSX.utils.json_to_sheet(arrData);
          /* add to workbook */
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          /* generate an XLSX file */
          XLSX.writeFile(wb, "order_report.xlsx");
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    async getOrderReport(period, period2, offset, limit) {
      try {
        const res = await this.$api.report.getOrderReport(period, period2, offset - 1, limit)
        if (res.status === 200) {
          this.orders = res.data.data
          this.total = res.data.meta.total
          this.page = offset
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    formatPrice(value) {
      return value ? value.toLocaleString('id', {minimumFractionDigits: 2}) : value
    },
    formatDate(value, format = "YYYY-MM-DD") {
      return value ? moment(String(value)).format(format).toString() : value
    }
  }
}
</script>
<style scoped>
th {
  width: 14.28%;
}
</style>
